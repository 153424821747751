import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomeView.vue";
import Portfolio from "@/views/Portfolio.vue";

// Definimos 3 rutas para el router 1: / , 2: /portfolio y  3: /contact
const routes = [
  { path: "/", name: "HomeView", component: Home },
  { path: "/portfolio", name: "PortfolioView", component: Portfolio },
  {
    path: "/contact",
    name: "ContactView",
    component: () => import("../views/ContactForm.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
