<template>
  <MainLayout>
    <main id="portfolio" class="py-20  bg-gray-100 dark:bg-gray-800">
      <div class="container mx-auto px-6">
        <header class="text-center mb-12">
          <h1
            class="text-4xl font-heading md:text-5xl font-bold mb-4 text-gray-800 dark:text-gray-200 animate-fade-in-down">
            {{ $t('portfolio.title') }}
          </h1>
          <p class="text-xl text-gray-600 dark:text-gray-400 mb-8 animate-fade-in-up max-w-2xl mx-auto">
            {{ $t('portfolio.description') }}
          </p>
        </header>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 animate-fade-in-down">
          <ProjectCard v-for="project in projects" :key="project.id" :title="project.title"
            :description="project.description" :image="project.image" :technologies="project.technologies"
            :link="project.link" />
        </div>
      </div>
    </main>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import ProjectCard from '@/components/molecules/ProjectCard.vue';

export default {
  name: 'PortfolioView',
  components: {
    MainLayout,
    ProjectCard
  },
  data() {
    return {
      projects: [
        {
          id: 1,
          title: 'Sistema de ventas',
          description: 'Proyecto realizado durante mi formación consistes en control de inventario, gestión de usuarios, plataforma de pagos y facturación.',
          image: require('@/assets/images/asset-test.webp'),
          link: 'https://demo-sistema.devluisarias.online/',
          technologies: ['PHP', 'Laravel', 'JavaScript', 'MySQL', 'Boostrap']
        },
        {
          id: 2,
          title: 'Aplicacion de tareas por hacer (To Do)',
          description: 'Aplicación para tomar notas o tareas que se necesitan realizar.',
          image: require('@/assets/images/asset-test.webp'),
          link: 'https://demo-todoapp.devluisarias.online/',
          technologies: ['Vue.JS', 'LocalStorange', 'DaisyUI', 'Tailwind']
        },
        {
          id: 3,
          title: 'Sitio web presentacion de empresa',
          description: 'Nombrado PaintPro es una empresa ficticia que ofrece servicios en el área de pintura residencial.',
          image: require('@/assets/images/asset-test.webp'),
          link: 'https://demopaintpro.devluisarias.online/',
          technologies: ['Vue.JS', 'Tailwind']
        },
        {
          id: 4,
          title: 'Calculadora financiera',
          description: 'Proyecto realizado durante mi formacion, Herramienta para obtener Anualidades, Interes compuesto y Amortizacion',
          image: require('@/assets/images/asset-test.webp'),
          link: 'https://demo-calfinanciera.devluisarias.online/',
          technologies: ['PHP', 'JavaScript', 'Bootstrap']
        }
      ]
    };
  }
};
</script>

<style scoped>
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate-fade-in-down {
  animation: fadeInDown 1s ease-out;
}

.animate-fade-in-up {
  animation: fadeInUp 1s ease-out;
}
</style>