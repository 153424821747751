import { createI18n } from 'vue-i18n';

// Importacion los archivos de traducción
import en from '@/i18n/en.json';
import es from '@/i18n/es.json';

const messages = {
  en,
  es,
};

const i18n = createI18n({
  locale: 'es', // Idioma por defecto
  fallbackLocale: 'en', // Idioma de respaldo
  messages,
});

export default i18n;
