<template>
  <div>
    <NavBar />
    <main>
      <slot /> <!-- Renderiza contenido dinámico aquí -->
    </main>
    <Footer />
  </div>
</template>

<script>
import NavBar from '@/components/organisms/NavBar.vue';
import Footer from '@/components/organisms/Footer.vue';

export default {
  components: {
    NavBar,
    Footer
  }
};
</script>