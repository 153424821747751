<template>
  <section class="py-16 bg-gray-100 dark:bg-gray-800">
    <div class="container mx-auto px-6">
      <h2 class="text-4xl font-bold font-heading text-center text-gray-800 dark:text-white mb-8">
        Mis Habilidades
      </h2>
      <div class="grid grid-cols-2 md:grid-cols-4 gap-6">
        <div v-for="skill in skills" :key="skill.name"
          class="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-md text-center transition duration-300 ease-in-out transform hover:scale-105">
          <component :is="skill.icon" :class="skill.class" />
          <h3 class="font-semibold font-mono text-gray-800 dark:text-white text-xl">{{ skill.name }}</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { Code, Server, Database, MonitorCheck } from 'lucide-vue-next'

const skills = ref([
  { name: 'Vue.JS', icon: Code, class: 'w-12 h-12 mx-auto mb-4 text-yellow-500' },
  { name: 'Node.JS', icon: Server, class: 'w-12 h-12 mx-auto mb-4 text-green-500' },
  { name: 'Laravel', icon: MonitorCheck, class: 'w-12 h-12 mx-auto mb-4 text-red-500' },
  { name: 'Bases de Datos', icon: Database, class: 'w-12 h-12 mx-auto mb-4 text-blue-500' },
])
</script>