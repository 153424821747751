<template>
  <footer class="bg-gradient-to-r from-gray-900 to-gray-800 text-white py-6">
    <div class="container mx-auto px-4">
      <div class="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <!-- Copyright -->
        <p class="text-gray-400 text-sm">
          &copy; {{ currentYear }} Mi Portafolio
        </p>

        <!-- Social Links -->
        <div class="flex space-x-4">
          <a v-for="social in socialLinks" :key="social.name" :href="social.link" :aria-label="social.name"
            class="text-gray-400 hover:text-white transition-colors duration-300 p-2 rounded-full hover:bg-gray-700"
            target="_blank" rel="noopener noreferrer">
            <component :is="social.icon" class="w-5 h-5" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from "vue";
import { GithubIcon, LinkedinIcon, TwitterIcon } from "lucide-vue-next";
import { defineComponent } from "vue";
defineComponent({
  name: "FooterComponent", // Define el nombre del componente
});
const currentYear = computed(() => new Date().getFullYear());

const socialLinks = [
  {
    name: "GitHub",
    link: "https://github.com/LuisC4",
    icon: GithubIcon,
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/luis-jose-arias-reyes-948507311/",
    icon: LinkedinIcon,
  },
  {
    name: "Twitter",
    link: "https://x.com/Luis78791",
    icon: TwitterIcon,
  },
];
</script>
