<template>
  <nav class="fixed w-full top-0 left-0 right-0 z-50 px-4 py-2 sm:px-6 lg:px-8">
    <div
      class="max-w-7xl mx-auto bg-white bg-opacity-70 dark:bg-gray-600 dark:bg-opacity-70 backdrop-filter backdrop-blur-lg shadow-lg rounded-full transition-colors duration-300">
      <div class="flex items-center justify-between h-16 px-4 sm:px-6">
        <!-- Logo -->
        <div class="flex-shrink-0">
          <a href="/" class="flex-shrink-0">
            <img class="h-8 w-auto rounded-md" src="@/assets/images/logo.webp" alt="Logo" />
          </a>
        </div>

        <!-- Desktop Navigation - Centered -->
        <div class="hidden md:flex flex-1 justify-center">
          <div class="flex items-baseline space-x-4">
            <a v-for="link in links" :key="link.name" :href="link.href" :class="[
              isActive(link.href)
                ? 'bg-primary-500 text-white text-base font-bold px-4 py-2.5 shadow-md'
                : 'text-gray-800 dark:text-white hover:bg-primary-500/10 dark:hover:bg-primary-500/20 text-sm font-medium px-3 py-2',
              'rounded-full transition-all duration-300 font-heading relative overflow-hidden group'
            ]" :aria-current="isActive(link.href) ? 'page' : undefined">
              <span class="relative z-10">{{ link.name }}</span>
              <div
                class="absolute inset-0 bg-primary-500 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-300 ease-out rounded-full">
              </div>
            </a>
          </div>
        </div>

        <!-- Theme Toggle - Desktop -->
        <div class="hidden md:block">
          <button @click="toggleTheme"
            class="text-gray-800 dark:text-white hover:bg-primary-500/10 dark:hover:bg-primary-500/20 p-2 rounded-full transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            <SunIcon v-if="isDarkMode" class="h-5 w-5" />
            <MoonIcon v-else class="h-5 w-5" />
          </button>
        </div>

        <!-- Mobile Menu Button -->
        <div class="md:hidden">
          <button @click="toggleMenu" type="button"
            class="bg-primary-500 inline-flex items-center justify-center p-2 rounded-full text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            aria-controls="mobile-menu" :aria-expanded="isOpen">
            <span class="sr-only">{{ isOpen ? 'Cerrar menú' : 'Abrir menú' }}</span>
            <MenuIcon v-if="!isOpen" class="block h-5 w-5" />
            <XIcon v-else class="block h-5 w-5" />
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile Menu -->
    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100" leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <div v-show="isOpen" class="md:hidden mt-2 bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <a v-for="link in links" :key="link.name" :href="link.href" @click="closeMenu" :class="[
            isActive(link.href)
              ? 'bg-primary-500 text-white text-lg font-bold px-4 py-3'
              : 'text-gray-800 dark:text-white hover:bg-primary-500/10 dark:hover:bg-primary-500/20 text-base font-medium px-3 py-2',
            'block rounded-xl transition-all duration-300 font-heading relative overflow-hidden'
          ]" :aria-current="isActive(link.href) ? 'page' : undefined">
            {{ link.name }}
          </a>
          <!-- Theme Toggle - Mobile -->
          <button @click="toggleTheme"
            class="w-full flex items-center justify-between text-gray-800 dark:text-white hover:bg-primary-500/10 dark:hover:bg-primary-500/20 px-3 py-2 rounded-xl text-base font-medium transition-all duration-300 font-heading">
            <span>{{ isDarkMode ? 'Modo Claro' : 'Modo Oscuro' }}</span>
            <span class="ml-2">
              <SunIcon v-if="isDarkMode" class="h-5 w-5" />
              <MoonIcon v-else class="h-5 w-5" />
            </span>
          </button>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { MenuIcon, XIcon, SunIcon, MoonIcon } from 'lucide-vue-next'

const route = useRoute()
const isOpen = ref(false)
const isDarkMode = ref(false)

// Verificar y cargar la preferencia de tema al montar el componente
onMounted(() => {
  const savedTheme = localStorage.getItem('isDarkMode')
  if (savedTheme) {
    isDarkMode.value = JSON.parse(savedTheme)
    if (isDarkMode.value) {
      document.documentElement.classList.add('dark')
    }
  }
})

// Método para cambiar el tema
const toggleTheme = () => {
  isDarkMode.value = !isDarkMode.value
  // Actualizar el almacenamiento local y la clase de modo oscuro en el documento
  localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode.value))
  document.documentElement.classList.toggle('dark', isDarkMode.value)
}

const links = [
  { name: 'Inicio', href: '/' },
  { name: 'Portafolio', href: '/portfolio' },
  { name: 'Contacto', href: '/contact' }
]

const isActive = (href) => {
  return route.path === href
}

const toggleMenu = () => {
  isOpen.value = !isOpen.value
}

const closeMenu = () => {
  isOpen.value = false
}

// Event listeners para el resize de la ventana
const closeMenuOnResize = () => {
  if (window.innerWidth >= 768 && isOpen.value) {
    isOpen.value = false
  }
}

onMounted(() => {
  window.addEventListener('resize', closeMenuOnResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', closeMenuOnResize)
})
</script>

<style scoped>
@media (max-width: 767px) {
  .rounded-full {
    border-radius: 0.75rem;
  }
}

/* Efecto hover para los links no activos */
.group:hover span {
  color: white;
  transition-duration: 300ms;
}

/* Asegurarse de que el texto permanezca visible sobre el fondo al hacer hover */
.group span {
  position: relative;
  z-index: 10;
}
</style>