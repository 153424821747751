<template>
  <section class="py-20 bg-white dark:bg-gray-800" id="aboutMe">
    <div class="container mx-auto px-6">
      <h2 class="text-4xl font-bold mb-8 text-center font-heading">Sobre Mí</h2>
      <article class="flex flex-col md:flex-row items-center justify-between">
        <div class="md:w-1/3 mb-8 md:mb-0">
          <img src="@/assets/images/logo.webp" alt="Luis Arias" class="rounded-full shadow-lg mx-auto" width="300"
            height="300" />
        </div>
        <div class="md:w-2/3 md:pl-12">
          <p class="text-xl">
            Como desarrollador, busco siempre nuevas formas de mejorar mis habilidades y adquirir nuevos conocimientos.
            Nuevas formas de analizar datos y desarrollar modelos predictivos que puedan ser utilizados en diferentes
            proyectos.
          </p>
          <p class="text-xl mt-6 mb-6">
            Finalicé mi pre-especialización, adquiriendo conocimientos y experiencias en Machine Learning y Deep
            Learning.
          </p>
          <h3 class="text-3xl font-semibold mb-4 font-heading">Educación</h3>
          <div class="max-w-3xl mx-4">
            <div
              class="relative pl-8 before:content-[''] before:w-px before:h-full before:bg-blue-500 before:absolute before:left-0 before:top-0">
              <div class="absolute w-4 h-4 bg-blue-500 rounded-full left-0 top-0 transform -translate-x-1/2"></div>
              <h3 class="text-xl font-semibold text-gray-800 dark:text-white"> Estudios superiores - El Salvador</h3>
              <p class="text-gray-600 dark:text-gray-400"> Universidad Gerardo Barrios </p>
              <p class="text-sm text-gray-500 dark:text-gray-500"> 2019 - 2024</p>
              <p class="mt-2 text-gray-700 dark:text-gray-300"> Ingeniería en Sistemas y Redes Informáticas,
                Especialización en Inteligencia Artificial y Tecnologías Emergentes </p>
            </div>
          </div>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutMe",
  components: {},
};
</script>
