<template>
  <section class="py-20 bg-primary-600 text-white">
    <div class="container mx-auto px-6 text-center">
      <h2 class="text-3xl font-bold mb-4 font-heading">¿Listo para trabajar juntos?</h2>
      <p class="text-xl mb-8">Estoy disponible para proyectos freelance y oportunidades de tiempo completo.</p>
      <a href="/contact"
        class="bg-white text-blue-600 hover:bg-blue-100 font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105 inline-block font-heading text-lg">
        Contáctame
      </a>
    </div>
  </section>
</template>